import {
  CustomContext,
  CustomContextItem,
} from '@zarn/vendor/dist/chat-service';
import type {
  ChatContextFromContent,
  ChatContextFromDocIds,
  ChatContexts,
  ChatMessage,
  ChatMessageEvidence,
  ChatResponseForm,
  ChatResponseItem,
  FunctionCallRequest,
} from '@zarn/vendor/dist/search';
import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { RetrievalUnitEnum } from 'common/enums';
import { serializeKey } from 'common/utils/serialize.helpers';
import {
  isChatContextWithDocs,
  isChatContextWithDocsIds,
  isContextPayloadWithHits,
} from 'containers/Chat/hooks/helpers';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import type {
  ChatContext,
  ChatMessageElement,
  ChatPayload,
  ChatResponse,
  ContentContext,
  DocsIdsContext,
  Evidence,
  FunctionCallProperties,
} from './chatApi.types';

const serializeFromDocIds = (
  fromDocIds: DocsIdsContext
): ChatContextFromDocIds => ({
  document_ids: fromDocIds.docId,
  retrieval_unit: fromDocIds.retrievalUnit,
});

const mapRetrievalUnitDataToCustomContextItem = (
  hit: RetrievalUnitData
): CustomContextItem => ({
  content: {
    abstract: hit.abstractContent,
    date: hit.date || undefined,
    source: hit.source || undefined,
    title: hit.title,
    url: hit.shareUri || hit.uri || undefined,
  },
  document_id: hit.document.id,
});

const serializeFromDocs = (
  hits: RetrievalUnitData[] | undefined = []
): CustomContext => ({
  items: hits.map(mapRetrievalUnitDataToCustomContextItem),
});

const serializeFromDocumentContent = (
  fromDocContent: ContentContext
): ChatContextFromContent => ({
  content: fromDocContent.docContent,
  document_id: fromDocContent.docId,
});

export const serializeChatContext = (
  context: ChatContext & {
    payload?: { hits?: RetrievalUnitData[] };
  }
): ChatContexts => {
  const result: ChatContexts & { custom_context?: any } = {};

  if (isChatContextWithDocsIds(context) && context.fromDocIds) {
    result.from_document_ids = serializeFromDocIds(context.fromDocIds);
  }

  if (context.fromDocContent) {
    result.from_document_content = context.fromDocContent.map(
      serializeFromDocumentContent
    );
  }

  if (isChatContextWithDocs(context) && context.fromDocs) {
    result.custom_context = serializeFromDocs(context.fromDocs);
  } else if (isContextPayloadWithHits(context)) {
    result.custom_context = serializeFromDocs(context.payload?.hits);
  }

  return result;
};

const serializeEvidenceItem = (
  evidenceItem: Evidence
): ChatMessageEvidence => ({
  document_id: evidenceItem.documentId ?? undefined,
  text_extract: evidenceItem.textExtract,
  ...serializeKey(evidenceItem, 'anchorText', 'anchor_text'),
  ...serializeKey(evidenceItem, 'explanation'),
  ...serializeKey(evidenceItem, 'uri'),
  ...serializeKey(evidenceItem, 'documentHitUrl', 'document_hit_url'),
});

export const serializeBotParams = (
  botParams: Record<string, any>
): Record<string, any> => ({
  ...serializeKey(botParams, 'tagName', 'tag_name'),
  ...serializeKey(botParams, 'tagDescription', 'tag_description'),
  ...serializeKey(botParams, 'tagSize', 'tag_size'),
  ...serializeKey(botParams, 'tagDate', 'tag_date'),
  ...serializeKey(botParams, 'searchContext', 'search_context'),
  ...serializeKey(botParams, 'generateReport', 'generate_report'),
});

const serializeChatResponseForm = (
  conversationItem: ChatMessageElement
): ChatMessage => {
  const chatMessageElement: ChatMessage = {
    content: conversationItem.content,
    sender: conversationItem.sender,
    ...(conversationItem.image ? { image_uri: conversationItem.image } : {}),
  };

  if (conversationItem.evidences) {
    chatMessageElement.evidences = conversationItem.evidences.map(
      serializeEvidenceItem
    );
  }

  if (conversationItem.functionCallRequest) {
    chatMessageElement.function_call_request =
      conversationItem.functionCallRequest;
  }

  return chatMessageElement;
};

export const serializeChatSearchPayload = ({
  chatResponseForm,
}: ChatPayload): ChatResponseForm => ({
  bot_type: chatResponseForm.botType,
  context: serializeChatContext(chatResponseForm.context),
  conversation: chatResponseForm.conversation.map(serializeChatResponseForm),
  ...(chatResponseForm.botParams
    ? { bot_params: serializeBotParams(chatResponseForm.botParams) }
    : {}),
});

export const deserializeRetrievalUnit = (
  retrievalUnit: string
): RetrievalUnitEnum => {
  switch (retrievalUnit) {
    case 'document':
      return RetrievalUnitEnum.Document;
    case 'sentence':
      return RetrievalUnitEnum.Sentence;
    case 'chunk':
      return RetrievalUnitEnum.Chunk;
    default:
      return RetrievalUnitEnum.Document;
  }
};

const deserializeFromDocIds = (
  fromDocIds: ChatContextFromDocIds
): DocsIdsContext => ({
  contextFields: [],
  docId: fromDocIds.document_ids,
  retrievalUnit: deserializeRetrievalUnit(fromDocIds.retrieval_unit),
});

const deserializeFromDocumentContent = (
  fromDocContent: ChatContextFromContent
): ContentContext => ({
  docContent: fromDocContent.content,
  docId: fromDocContent.document_id,
});

const deserializeChatContext = (context: ChatContexts): ChatContext => ({
  fromDocContent: context.from_document_content
    ? context.from_document_content.map(deserializeFromDocumentContent)
    : undefined,
  fromDocIds: context.from_document_ids
    ? deserializeFromDocIds(context.from_document_ids)
    : undefined,
  // TODO
  fromDocs: undefined,
});

export const deserializeSender = (sender: string): ChatMessageSenderEnum => {
  switch (sender) {
    case 'bot':
      return ChatMessageSenderEnum.Bot;
    case 'user':
    default:
      return ChatMessageSenderEnum.User;
  }
};

export const buildEvidenceLabel = (index: number): string => `[^${index + 1}]`;

export const getEvidenceDocumentId = (evidence: ChatMessageEvidence) => {
  if ('document_id' in evidence && evidence.document_id) {
    return `${evidence.document_id}`;
  }

  if (evidence.document_hit_url) {
    const url = new URLSearchParams(evidence.document_hit_url);
    if (url.get('property_name') === 'id' && url.get('property_values')) {
      return url.get('property_values');
    }
  }

  return null;
};

export const deserializeEvidenceItem = (
  evidenceItem: ChatMessageEvidence,
  index: number
): Evidence => ({
  documentId: getEvidenceDocumentId(evidenceItem),
  textExtract: evidenceItem.text_extract,
  ...serializeKey(evidenceItem, 'anchor_text', 'anchorText'),
  ...serializeKey(evidenceItem, 'explanation'),
  ...serializeKey(evidenceItem, 'uri'),
  ...serializeKey(evidenceItem, 'document_hit_url', 'documentHitUrl'),
  label: buildEvidenceLabel(index),
});

export const deserializeContent = (
  content: string,
  evidences: Evidence[]
): string =>
  evidences.reduce(
    (acc, evidence) =>
      evidence.anchorText && evidence.label
        ? acc.replaceAll(evidence.anchorText, evidence.label)
        : acc,
    content
  );

export const deserializeFunctionCallProps = (
  props: FunctionCallRequest
): FunctionCallProperties => ({
  name: props.name,
  ...(props.params ? { params: props.params } : {}),
});

export const deserializeChatMessage = (
  message: ChatMessage
): ChatMessageElement => {
  const sender = deserializeSender(message.sender);
  const evidences = message.evidences
    ? message.evidences.map(deserializeEvidenceItem)
    : undefined;

  const result: ChatMessageElement = {
    content: message.content,
    sender,
    ...(message.function_call_request
      ? {
          functionCallRequest: deserializeFunctionCallProps(
            message.function_call_request
          ),
        }
      : {}),
    ...(message.image_uri ? { image: message.image_uri } : {}),
  };

  if (evidences) {
    result.evidences = evidences;
  }

  return result;
};

export const deserializeChatResponse = (
  response: ChatResponseItem
): ChatResponse => ({
  botType: response.bot_type,
  context: deserializeChatContext(response.context),
  conversation: response.conversation.map(deserializeChatMessage),
});

export const getDocumentHitUrl = ({
  documentHitUrl,
}: {
  documentHitUrl?: string;
}) => documentHitUrl;
