import React, { createContext, FC, ReactNode, useState } from 'react';

import { ChatResponse } from 'api/chatApi/chatApi.types';
import { RetrievalUnitEnum } from 'common/enums';

export interface HomePageContextValue {
  botParams: Record<string, any> | null;
  chatResponse: ChatResponse | null;
  retrievalUnit: RetrievalUnitEnum | null;
  setBotParams: (botParams: Record<string, any> | null) => void;
  setChatResponse: (chatResponse: ChatResponse | null) => void;
  setRetrievalUnit: (retrievalUnit: RetrievalUnitEnum | null) => void;
}

export const HomePageContext = createContext<HomePageContextValue | null>(null);

export const HomePageContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [chatResponse, setChatResponse] = useState<ChatResponse | null>(null);
  const [retrievalUnit, setRetrievalUnit] = useState<RetrievalUnitEnum | null>(
    null
  );
  const [botParams, setBotParams] = useState<Record<string, any> | null>(null);

  return (
    <HomePageContext.Provider
      value={{
        botParams,
        chatResponse,
        retrievalUnit,
        setBotParams,
        setChatResponse,
        setRetrievalUnit,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};
